*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}
.App{
  display: flex;
  background-color: black;
}
.rdt.input {
  margin-bottom: 10px;
}
.rdt.input .form-control {
  width: calc(100% - 15px);
  padding: 10px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 7px;
  color: white;
  font-family: "Balsamiq Regular";
  font-size: 16px;
}

.rdtOpen .rdtPicker {
  background: #171717;
  border: 2px solid white;
  margin-top: 5px;
  border-radius: 7px;
  font-family: "Balsamiq Regular";
  th {
    border-bottom: 1px solid #ffffff1a;
  }
}

.rdtPrev span, .rdtSwitch, .rdtNext span {
  color: white;
}

.rdtPrev:hover, .rdtSwitch:hover, .rdtNext:hover {
  background: #585858 !important;
}

.dow {
  color: white;
}

.rdtDay {
  color: white;
}

.rdtOld {
  color: #797979 !important;
}

.rdtDay:hover {
  background: #585858 !important;
}

.rdtActive:hover {
  background-color: #ff0046 !important;
}

.rdtToday.rdtActive::before {
  border-bottom: 7px solid white !important;
}

.rdtToday::before {
  border-bottom: 7px solid #ff0046 !important;
}

.rdtActive {
  background-color: #ff0046 !important;
}

.rdtTimeToggle {
  color: white;
  background-color: #ff0046;
  border-radius: 7px;
}

.rdtTimeToggle:hover {
  background-color: #ac002e !important;
}

.rdtTime .rdtSwitch {
  color: white;
  background-color: #ff0046;
  border-radius: 7px;
  cursor: pointer;
}

.rdtTime .rdtSwitch:hover {
  background-color: #ac002e !important;
}

.rdtCount {
  color: white;
}

.rdtBtn {
  color: white;
}

.rdtBtn:hover {
  background: #585858 !important;
}

.rdtTime .rdtCounters {
  margin-top: 5px;
}